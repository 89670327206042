// database.ts
// Mark 0,10,20 sind Risikogruppen, sie können innerhalb der gruppe nur einfach verrechnet werden
// Mark 30 (Rauchen oder Chronisch Obstruktive Lungenerkrankung) sind Faktoren die sich ausschließen
// Mark 40 BMI sind Faktoren die sich ausschließen
// Mark 50 TSH sind Faktoren die sich ausschließen
// Mark 60 TBS sind Faktoren die sich ausschließen
// Mark 70 Diabetes sind Faktoren die sich ausschließen
// Mark 80 Hüftfraktur < oder > 12 Monate
// Mark 99 kann nicht verrechnet werden, hier wird eine basis diagnostik empfohlen
export const Wirbelfrakturen50 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 3.0,
        id: "w50_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w50_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 3.0,
        id: "w50_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 5.4,
        id: "w50_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w50_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 3.0,
        id: "w50_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 5.3,
        id: "w50_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen55 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.9791,
        id: "w55_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w55_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.9791,
        id: "w55_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 5.28125,
        id: "w55_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w55_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.9791,
        id: "w55_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 5.28125,
        id: "w55_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen60 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.96,
        id: "w60_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w60_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.96,
        id: "w60_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 5.225,
        id: "w60_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w60_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.96,
        id: "w60_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 5.225,
        id: "w60_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen65 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.9359,
        id: "w65_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w65_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.9359,
        id: "w65_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 5.13125,
        id: "w65_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w65_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.9359,
        id: "w65_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 5.13125,
        id: "w65_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen70 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.9,
        id: "w70_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w70_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.9,
        id: "w70_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 5.0,
        id: "w70_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w70_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.9,
        id: "w70_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 5.0,
        id: "w70_7",
        mark: 0,
      },
    ],
  },
];

export const Wirbelfrakturen75 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.828125,
        id: "w75_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w75_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.828125,
        id: "w75_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 4.83125,
        id: "w75_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w75_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.828125,
        id: "w75_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 4.83125,
        id: "w75_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen80 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.7375,
        id: "w80_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w80_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.7375,
        id: "w80_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 4.625,
        id: "w80_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w80_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.7375,
        id: "w80_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 4.625,
        id: "w80_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen85 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.628125,
        id: "w85_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w85_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.628125,
        id: "w85_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 4.38125,
        id: "w85_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w85_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.628125,
        id: "w85_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 4.38125,
        id: "w85_7",
        mark: 0,
      },
    ],
  },
];
export const Wirbelfrakturen90 = [
  {
    title: "Wirbelfraktur(en)",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Wirbelfraktur(en) im letzten Jahr",
        value: 2.5,
        id: "w90_1",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
        value: 2.0,
        id: "w90_2",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotischer Wirbelbrüche",
        value: 2.5,
        id: "w90_3",
        mark: 0,
      },
      {
        label:
          "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotischer Wirbelbrüche",
        value: 4.1,
        id: "w90_4",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 1 Genant",
        value: 2.0,
        id: "w90_5",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 2 Genant",
        value: 2.5,
        id: "w90_6",
        mark: 0,
      },
      {
        label: "Wirbelbruch Schweregrad max Grad 3 Genant",
        value: 4.1,
        id: "w90_7",
        mark: 0,
      },
    ],
  },
];

export const HüftfrakturUndAndereFrakturen50 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 3.8,
        id: "h50_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.4,
        id: "h50_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h50_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.5,
        id: "h50_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.5,
        id: "h50_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen55 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 3.809375,
        id: "h55_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.40625,
        id: "h55_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h55_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.525,
        id: "h55_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.521875,
        id: "h55_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen60 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 3.8625,
        id: "h60_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.425,
        id: "h60_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h60_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.55,
        id: "h60_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.5625,
        id: "h60_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen65 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 3.959375,
        id: "h65_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.45625,
        id: "h65_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h65_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.575,
        id: "h65_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.621875,
        id: "h65_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen70 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 4.1,
        id: "h70_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.5,
        id: "h70_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h70_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.6,
        id: "h70_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.7,
        id: "h70_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen75 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 4.284375,
        id: "h75_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.55625,
        id: "h75_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h75_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.625,
        id: "h75_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.796875,
        id: "h75_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen80 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 4.5125,
        id: "h80_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.625,
        id: "h80_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h80_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.65,
        id: "h80_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 1.9125,
        id: "h80_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen85 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 4.784375,
        id: "h85_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.70625,
        id: "h85_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h85_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.675,
        id: "h85_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 2.046875,
        id: "h85_5",
        mark: 1,
      },
    ],
  },
];
export const HüftfrakturUndAndereFrakturen90 = [
  {
    title: "Hüftfraktur und andere Frakturen",
    subTitle: "",
    data: [
      {
        label: "Hüftfraktur im letzten Jahr",
        value: 5.1,
        id: "h90_1",
        mark: 80,
      },
      {
        label: "Hüftfraktur > 12 Monate Zeitabstand",
        value: 2.8,
        id: "h90_2",
        mark: 80,
      },
      {
        label: "Humerusfraktur",
        value: 1.7,
        id: "h90_3",
        mark: 1,
      },
      {
        label: "Handgelenksfraktur",
        value: 1.7,
        id: "h90_4",
        mark: 1,
      },
      {
        label: "Beckenfraktur",
        value: 2.2,
        id: "h90_5",
        mark: 1,
      },
    ],
  },
];

export const AllgemeineRisikofaktoren50 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.2,
        id: "a50_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.9,
        id: "a50_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a50_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a50_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.0,
        id: "a50_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.6,
        id: "a50_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.3,
        id: "a50_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren55 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.215625,
        id: "a55_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.9,
        id: "a55_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a55_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a55_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.003125,
        id: "a55_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.60625,
        id: "a55_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.3,
        id: "a55_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren60 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.2375,
        id: "a60_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.9,
        id: "a60_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a60_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a60_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.0375,
        id: "a60_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.625,
        id: "a60_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.3,
        id: "a60_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren65 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.265625,
        id: "a65_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.9,
        id: "a65_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a65_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a65_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.103125,
        id: "a65_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.65625,
        id: "a65_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.3,
        id: "a65_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren70 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.3,
        id: "a70_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.9,
        id: "a70_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a70_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a70_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.2,
        id: "a70_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.7,
        id: "a70_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.3,
        id: "a70_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren75 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.340625,
        id: "a75_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.875,
        id: "a75_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a75_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a75_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.328125,
        id: "a75_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.75625,
        id: "a75_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.325,
        id: "a75_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren80 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.3875,
        id: "a80_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.85,
        id: "a80_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a80_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a80_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.4875,
        id: "a80_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.825,
        id: "a80_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.35,
        id: "a80_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren85 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.440625,
        id: "a85_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.825,
        id: "a85_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a85_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a85_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.678125,
        id: "a85_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 1.90625,
        id: "a85_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.375,
        id: "a85_7",
        mark: 40,
      },
    ],
  },
];
export const AllgemeineRisikofaktoren90 = [
  {
    title: "Allgemeine Risikofaktoren",
    subTitle: "",
    data: [
      {
        label: "Mutter oder Vater mit Hüftbruch",
        value: 1.5,
        id: "a90_1",
        mark: 1,
      },
      {
        label: "Erheblicher Alkoholkonsum (ab 50 g/Tag)",
        value: 1.8,
        id: "a90_2",
        mark: 1,
      },
      {
        label: "Rauchen (aktuell >10 Zigaretten/Tag)",
        value: 1.5,
        id: "a90_3",
        mark: 30,
      },
      {
        label: "Chronische Obstruktive Lungenerkrankung",
        value: 1.3,
        id: "a90_4",
        mark: 30,
      },
      {
        label: "Body Mass Index BMI ≤ 15 kg/m2",
        value: 2.9,
        id: "a90_5",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 15 - 18,5 kg/m2",
        value: 2.0,
        id: "a90_6",
        mark: 40,
      },
      {
        label: "Body Mass Index BMI 18,5 - 20 kg/m",
        value: 1.4,
        id: "a90_7",
        mark: 40,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide50 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.7,
        id: "r50_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.7,
        id: "r50_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r50_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.3,
        id: "r50_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 4.3,
        id: "r50_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 5.4,
        id: "r50_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide55 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.69375,
        id: "r55_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.7,
        id: "r55_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r55_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.3,
        id: "r55_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 4.184375,
        id: "r55_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 5.36875,
        id: "r55_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide60 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.675,
        id: "r60_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.7,
        id: "r60_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r60_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.3,
        id: "r60_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 4.075,
        id: "r60_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 5.275,
        id: "r60_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide65 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.64375,
        id: "r65_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.7,
        id: "r65_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r65_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.3,
        id: "r65_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 3.971875,
        id: "r65_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 5.11875,
        id: "r65_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide70 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.6,
        id: "r70_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.7,
        id: "r70_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r70_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.3,
        id: "r70_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 4.0,
        id: "r70_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 4.9,
        id: "r70_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide75 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.54375,
        id: "r75_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 2.65,
        id: "r75_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.35,
        id: "r75_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.225,
        id: "r75_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 3.871875,
        id: "r75_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 4.61875,
        id: "r75_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide80 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.475,
        id: "r80_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 1.3,
        id: "r80_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r80_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.15,
        id: "r80_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 3.675,
        id: "r80_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 4.275,
        id: "r80_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide85 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.39375,
        id: "r85_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 1.25,
        id: "r85_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r85_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.075,
        id: "r85_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 3.409375,
        id: "r85_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 3.86875,
        id: "r85_6",
        mark: 10,
      },
    ],
  },
];
export const RheumatologieUndGlukokortikoide90 = [
  {
    title: "Rheumatologie und Glukokortikoide",
    subTitle:
      "Nur Axiale Spondyloarthritis und ein weiterer Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: "Axiale Spondyloarthritis",
        value: 1.3,
        id: "r90_1",
        mark: 1,
      },
      {
        label: "Rheumatoide Arthritis",
        value: 1.2,
        id: "r90_2",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent bis 2,5 mg/Tag > 3 Monate",
        value: 1.4,
        id: "r90_3",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent 2,5 – 7,5 mg/Tag > 3 Monate",
        value: 2.0,
        id: "r90_4",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent > 7,5 mg/Tag > 3 Monate",
        value: 3.0,
        id: "r90_5",
        mark: 10,
      },
      {
        label: "Prednisolonäquivalent ab 7,5 mg/Tag, neu im letzten Jahr",
        value: 3.4,
        id: "r90_6",
        mark: 10,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren50 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 1.9,
        id: "s50_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s50_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.3,
        id: "s50_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s50_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.2,
        id: "s50_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.7,
        id: "s50_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.5,
        id: "s50_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.5,
        id: "s50_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.0,
        id: "s50_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.3,
        id: "s50_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.4,
        id: "s50_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.6,
        id: "s50_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren55 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 1.925,
        id: "s55_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s55_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.30625,
        id: "s55_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s55_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.2,
        id: "s55_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.7,
        id: "s55_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.53125,
        id: "s55_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.521875,
        id: "s55_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.0284375,
        id: "s55_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.32375,
        id: "s55_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.43125,
        id: "s55_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.6125,
        id: "s55_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren60 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 1.95,
        id: "s60_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s60_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.325,
        id: "s60_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s60_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.2,
        id: "s60_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.7,
        id: "s60_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.545,
        id: "s60_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.5625,
        id: "s60_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.045,
        id: "s60_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.345,
        id: "s60_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.475,
        id: "s60_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.65,
        id: "s60_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren65 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 1.975,
        id: "s65_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s65_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.35625,
        id: "s65_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s65_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.2,
        id: "s65_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.7,
        id: "s65_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.56875,
        id: "s65_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.621875,
        id: "s65_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.0640625,
        id: "s65_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.36875,
        id: "s65_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.53125,
        id: "s65_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.7125,
        id: "s65_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren70 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 2.0,
        id: "s70_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s70_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.4,
        id: "s70_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s70_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.2,
        id: "s70_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.7,
        id: "s70_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.6,
        id: "s70_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.7,
        id: "s70_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.1,
        id: "s70_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.4,
        id: "s70_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.6,
        id: "s70_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.8,
        id: "s70_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren75 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 2.025,
        id: "s75_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s75_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.45625,
        id: "s75_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s75_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.25,
        id: "s75_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.725,
        id: "s75_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.65625,
        id: "s75_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.796875,
        id: "s75_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.1671875,
        id: "s75_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.44375,
        id: "s75_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.68125,
        id: "s75_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 1.9125,
        id: "s75_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren80 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 2.05,
        id: "s80_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s80_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.525,
        id: "s80_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s80_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.3,
        id: "s80_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.75,
        id: "s80_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.75,
        id: "s80_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 1.9125,
        id: "s80_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.28,
        id: "s80_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.505,
        id: "s80_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.775,
        id: "s80_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 2.05,
        id: "s80_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren85 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 2.075,
        id: "s85_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s85_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.60625,
        id: "s85_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s85_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.35,
        id: "s85_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.775,
        id: "s85_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 1.89375,
        id: "s85_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 2.046875,
        id: "s85_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.4528125,
        id: "s85_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.58875,
        id: "s85_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 1.88125,
        id: "s85_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 2.2125,
        id: "s85_12",
        mark: 20,
      },
    ],
  },
];
export const SturzrisikoassoziierteRisikofaktoren90 = [
  {
    title: "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    subTitle: "Nur ein Risikofaktor wählbar, siehe DVO Leitlinie",
    data: [
      {
        label: ">1 Sturz im letzten Jahr (1-Jahres Risiko RR)",
        value: 2.1,
        id: "s90_1",
        mark: 20,
      },
      {
        label: "1 Sturz im letzten Jahr",
        value: 1.6,
        id: "s90_2",
        mark: 20,
      },
      {
        label: "Chronische Hyponatriämie",
        value: 1.7,
        id: "s90_3",
        mark: 20,
      },
      {
        label: "Depression/Antidepressiva",
        value: 1.3,
        id: "s90_4",
        mark: 20,
      },
      {
        label: "Epilepsie",
        value: 1.4,
        id: "s90_5",
        mark: 20,
      },
      {
        label: "Immobilität (angewiesen sein auf eine Gehhilfe)",
        value: 1.8,
        id: "s90_6",
        mark: 20,
      },
      {
        label: "M. Alzheimer/Demenz",
        value: 2.1,
        id: "s90_7",
        mark: 20,
      },
      {
        label: "M.Parkinson",
        value: 2.2,
        id: "s90_8",
        mark: 20,
      },
      {
        label: "Multiple Sklerose",
        value: 2.7,
        id: "s90_9",
        mark: 20,
      },
      {
        label: "Opioide",
        value: 1.7,
        id: "s90_10",
        mark: 20,
      },
      {
        label: "Schlaganfall",
        value: 2.0,
        id: "s90_11",
        mark: 20,
      },
      {
        label: "Timed up and Go Test > 12 Sek.",
        value: 2.4,
        id: "s90_12",
        mark: 20,
      },
    ],
  },
];
export const Endokrinologie50 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e50_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.1,
        id: "e50_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.4,
        id: "e50_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.4,
        id: "e50_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.2,
        id: "e50_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.2,
        id: "e50_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie55 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e55_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.1,
        id: "e55_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.43125,
        id: "e55_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.396875,
        id: "e55_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.2,
        id: "e55_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.2,
        id: "e55_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie60 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e60_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.1,
        id: "e60_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.475,
        id: "e60_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.3625,
        id: "e60_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.2,
        id: "e60_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.2,
        id: "e60_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie65 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e65_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.1,
        id: "e65_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.53125,
        id: "e65_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.296875,
        id: "e65_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.2,
        id: "e65_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.2,
        id: "e65_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie70 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e70_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.1,
        id: "e70_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.6,
        id: "e70_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.2,
        id: "e70_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.2,
        id: "e70_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.2,
        id: "e70_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie75 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e75_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.15,
        id: "e75_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.68125,
        id: "e75_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 2.071875,
        id: "e75_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.225,
        id: "e75_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.25,
        id: "e75_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie80 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e80_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.2,
        id: "e80_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.775,
        id: "e80_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 1.9125,
        id: "e80_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.25,
        id: "e80_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.3,
        id: "e80_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie85 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e85_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.25,
        id: "e85_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 1.88125,
        id: "e85_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 1.721875,
        id: "e85_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.275,
        id: "e85_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.35,
        id: "e85_6",
        mark: 50,
      },
    ],
  },
];
export const Endokrinologie90 = [
  {
    title: "Endokrinologie",
    subTitle: "",
    data: [
      {
        label: "Diabetes mellitus Typ I",
        value: 2.2,
        id: "e90_1",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit 5-10 Jahren",
        value: 1.3,
        id: "e90_2",
        mark: 70,
      },
      {
        label: "Diabetes mellitus Typ II, seit > 10 Jahre",
        value: 2.0,
        id: "e90_3",
        mark: 70,
      },
      {
        label: "Primärer Hyperparathyreoidismus",
        value: 1.5,
        id: "e90_4",
        mark: 1,
      },
      {
        label: "TSH 0,1-0,45",
        value: 1.3,
        id: "e90_5",
        mark: 50,
      },
      {
        label: "TSH <0,1",
        value: 1.4,
        id: "e90_6",
        mark: 50,
      },
    ],
  },
];
export const WeitereErkrankungen50 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we50_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 2.1,
        id: "we50_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.5,
        id: "we50_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we50_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen55 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we55_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 2.09375,
        id: "we55_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.515625,
        id: "we55_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we55_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen60 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we60_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 2.075,
        id: "we60_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.5375,
        id: "we60_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we60_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen65 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we65_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 2.04375,
        id: "we65_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.565625,
        id: "we65_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we65_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen70 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we70_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 2.0,
        id: "we70_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.6,
        id: "we70_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we70_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen75 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we75_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 1.94375,
        id: "we75_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.640625,
        id: "we75_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we75_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen80 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we80_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 1.875,
        id: "we80_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.6875,
        id: "we80_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we80_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen85 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we85_1",
        mark: 1,
      },
      {
        label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
        value: 1.79375,
        id: "we85_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.740625,
        id: "we85_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we85_4",
        mark: 1,
      },
    ],
  },
];
export const WeitereErkrankungen90 = [
  {
    title: "Weitere Erkrankungen/Medikationen",
    subTitle: "",
    data: [
      {
        label: "Chronische Herzinsuffizienz",
        value: 1.5,
        id: "we90_1",
        mark: 1,
      },
      {
        label: "MGUS",
        value: 1.7,
        id: "we90_2",
        mark: 1,
      },
      {
        label: "Niereninsuffizienz CKD 3a, 3b, 4",
        value: 1.8,
        id: "we90_3",
        mark: 1,
      },
      {
        label: "Protonenpumpenhemmer > 3 Monate",
        value: 1.4,
        id: "we90_4",
        mark: 1,
      },
    ],
  },
];
export const TBS50 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs50_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs50_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs50_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs50_4",
        mark: 60,
      },
    ],
  },
];
export const TBS55 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs55_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs55_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs55_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs60_4",
        mark: 60,
      },
    ],
  },
];
export const TBS60 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs60_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs60_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs60_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs60_4",
        mark: 60,
      },
    ],
  },
];
export const TBS65 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs65_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs65_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs65_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs65_4",
        mark: 60,
      },
    ],
  },
];
export const TBS70 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs70_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs70_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs70_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs70_4",
        mark: 60,
      },
    ],
  },
];
export const TBS75 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs75_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs75_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs75_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs75_4",
        mark: 60,
      },
    ],
  },
];
export const TBS80 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs80_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs80_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs80_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs80_4",
        mark: 60,
      },
    ],
  },
];
export const TBS85 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs85_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs85_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs85_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs85_4",
        mark: 60,
      },
    ],
  },
];
export const TBS90 = [
  {
    title: "Trabecular Bone Score (TBS)",
    subTitle: "",
    data: [
      {
        label: "TBS Z-Score: -1,0 SD",
        value: 1.3,
        id: "tbs90_1",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -1,5 SD",
        value: 1.4,
        id: "tbs90_2",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,0 SD",
        value: 1.6,
        id: "tbs90_3",
        mark: 60,
      },
      {
        label: "TBS Z-Score: -2,5 SD",
        value: 1.8,
        id: "tbs90_4",
        mark: 60,
      },
    ],
  },
];
export const basis = [
  {
    label:
      "Jede Fraktur postmenopausal und beim Mann ab 50 Jahren mit Ausnahme von Finger, Zehen und Schädelfrakturen",
    value: 0,
    id: "f13",
    mark: 99,
  },
  {
    label: "Cushing-Syndrom und subklinischer Hyperkortisolismus",
    value: 0,
    id: "f29",
    mark: 99,
  },
  {
    label: "Wachstumshormonmangel bei Hypophyseninsuffizienz",
    value: 0,
    id: "f30",
    mark: 99,
  },
  {
    label: "Männlicher Hypogonadismus durch Hormonablative Therapie",
    value: 0,
    id: "f31",
    mark: 99,
  },
  {
    label: "Männlicher Hypogonadismus anderer Ursache",
    value: 0,
    id: "f32",
    mark: 99,
  },
  {
    label: "Aromatasehemmer mit Beginn der Therapie",
    value: 0,
    id: "mOhne1",
    mark: 99,
  },
  {
    label: "BII Magenresektion oder Gastrektomie",
    value: 0,
    id: "w3",
    mark: 99,
  },
  {
    label: "Bariatrische Operation",
    value: 0,
    id: "w4",
    mark: 99,
  },
  {
    label: "HIV",
    value: 0,
    id: "w6",
    mark: 99,
  },
  {
    label: "Zöliakie",
    value: 0,
    id: "r2",
    mark: 99,
  },
  {
    label: "Morbus Crohn",
    value: 0,
    id: "r3",
    mark: 99,
  },
  {
    label: "Colitis ulcerosa",
    value: 0,
    id: "r4",
    mark: 99,
  },
  {
    label: "Systemischer Lupus erythematodes",
    value: 0,
    id: "r5",
    mark: 99,
  },
];

export const frakturvorgeschichte = [
  {
    label: "Wirbelkörperfraktur (en) im letzten Jahr",
    value: 2.9,
    id: "f3",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: 1 osteoporotischer Wirbelbruch",
    value: 2.0,
    id: "f4",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: 2 osteoporotische Wirbelbrüche",
    value: 2.9,
    id: "f5",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: 3 oder mehr osteoporotische Wirbelbrüche",
    value: 5.0,
    id: "f6",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: Wirbelbruch Schweregrad max Grad 1 Genant",
    value: 2.0,
    id: "f7",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: Wirbelbruch Schweregrad max Grad 2 Genant",
    value: 2.9,
    id: "f8",
    mark: 0,
  },
  {
    label:
      "Wirbelfraktur(en) > 12 Monate Zeitabstand: Wirbelbruch Schweregrad max Grad 3 Genant",
    value: 5.0,
    id: "f9",
    mark: 0,
  },
  {
    label: "Hüftfraktur im letzten Jahr (1 Jahres Risiko)",
    value: 4.1,
    id: "f1",
    mark: 0,
  },
  {
    label: "Hüftfraktur, Zeitabstand > 12 Monate",
    value: 2.5,
    id: "f2",
    mark: 0,
  },
  {
    label: "Humerusfraktur",
    value: 1.7,
    id: "f10",
    mark: 0,
  },
  {
    label: "Beckenfraktur",
    value: 1.7,
    id: "f11",
    mark: 0,
  },
  {
    label: "Unterarmfraktur",
    value: 1.6,
    id: "f12",
    mark: 0,
  },
];

export const medikationenGC = [
  {
    label:
      "Dosis > 5 mg Prednisolonäquivalent/Tag begonnen oder erhöht im letzten Jahr",
    value: 4.9,
    id: "mGC4",
    mark: 10,
  },
  {
    label: "Dosis > 7,5 mg Prednisolonäquivalent/Tag > 3 Monate",
    value: 4.0,
    id: "mGC3",
    mark: 10,
  },
  {
    label: "Dosis 2,5-7,5 mg Prednisolonäquivalent/Tag > 3 Monate",
    value: 2.3,
    id: "mGC2",
    mark: 10,
  },
  {
    label: "Dosis < 2,5 mg Prednisolonäquivalent/Tag > 3 Monate",
    value: 1.4,
    id: "mGC1",
    mark: 10,
  },
  {
    label: "Rheumatoide Arthritis",
    value: 2.7,
    id: "mGC5",
    mark: 10,
  },
];

export const medikationenOhne = [
  {
    title: " Indikatoren für eine Basisdiagnostik",
    subTitle: " ",
    data: [
      {
        label: "Cushing-Syndrom und subklinischer Hyperkortisolismus",
        value: "-",
        id: "mOhne_1",
        mark: 99,
      },
      {
        label: "Wachstumshormonmangel bei Hypophyseninsuffizienz",
        value: "-",
        id: "mOhne_2",
        mark: 99,
      },
      {
        label: "Männlicher Hypogonadismus durch Hormonablative Therapie",
        value: "-",
        id: "mOhne_3",
        mark: 99,
      },
      {
        label: "Männlicher Hypogonadismus anderer Ursache",
        value: "-",
        id: "mOhne_4",
        mark: 99,
      },
      {
        label: "Aromatasehemmer mit Beginn der Therapie",
        value: "-",
        id: "mOhne_5",
        mark: 11,
      },
      {
        label: "BII Magenresektion oder Gastrektomie",
        value: "-",
        id: "mOhne_6",
        mark: 99,
      },
      {
        label: "Bariatrische Operation",
        value: "-",
        id: "mOhne_7",
        mark: 99,
      },
      {
        label: "Zöliakie",
        value: "-",
        id: "mOhne_8",
        mark: 99,
      },
      {
        label: "Morbus Crohn",
        value: "-",
        id: "mOhne_9",
        mark: 99,
      },
      {
        label: "Colitis ulcerosa",
        value: "-",
        id: "mOhne_10",
        mark: 99,
      },
      {
        label: "Systemischer Lupus erythematodes",
        value: "-",
        id: "mOhne_11",
        mark: 99,
      },
    ],
  },
];

export const endokrinologie = [
  {
    label: "Diabetes mellitus Typ 1",
    value: 2.5,
    id: "e1",
    mark: 12,
  },
  {
    label: "Diabetes mellitus Typ 2 Seit 5-10 Jahren",
    value: 1.2,
    id: "e2",
    mark: 12,
  },
  {
    label: "Diabetes mellitus Typ 2 Seit > 10 Jahren",
    value: 1.6,
    id: "e3",
    mark: 12,
  },
  {
    label: "Primärer Hyperparathyreoidismus",
    value: 2.2,
    id: "e4",
    mark: 12,
  },
  {
    label: "TSH Suppression < 0,1 mU/l",
    value: 1.2,
    id: "e6",
    mark: 12,
  },
  {
    label: "TSH Suppression 0,1 - 0,45 mU/l",
    value: 1.2,
    id: "e5",
    mark: 12,
  },
];

export const allgemein = [
  {
    label: "Body Mass Index BMI ≤ 15 kg/m2",
    value: 2.2,
    id: "a2",
    mark: 13,
  },
  {
    label: "Body Mass Index BMI 15 - 18,5 kg/m2 ",
    value: 1.7,
    id: "a3",
    mark: 13,
  },
  {
    label: "Body Mass Index BMI 18,5 -< 20 kg/m2",
    value: 1.3,
    id: "a4",
    mark: 13,
  },
  {
    label: "Alkoholkonsum > 50 g/Tag",
    value: 1.9,
    id: "a5",
    mark: 13,
  },
  {
    label: "Rauchen aktuell (aktuell >10 Zigaretten/Tag)",
    value: 1.5,
    id: "a6",
    mark: 13,
  },
  {
    label:
      "Hüftfraktur eines Elternteils, bis zum Alter von 75 Jahren berücksichtigen",
    value: 1.3,
    id: "a1",
    mark: 13,
  },
  {
    label: "Chronische Obstruktive Lungenerkrankung",
    value: 1.3,
    id: "a7",
    mark: 13,
  },
];

export const weitere = [
  {
    label: "Monoklonale Gammopathie unklarer Signifikanz (MGUS)",
    value: 2.0,
    id: "w5",
    mark: 14,
  },
  {
    label: "Niereninsuffizienz CKD 3a,3b,4",
    value: 1.6,
    id: "w2",
    mark: 14,
  },
  {
    label: "Herzinsuffizienz, chronisch",
    value: 1.5,
    id: "w1",
    mark: 14,
  },
  {
    label: "TBS Z-Score -2,5 SD",
    value: 1.8,
    id: "r9",
    mark: 15,
  },
  {
    label: "TBS Z-Score -2,0 SD",
    value: 1.6,
    id: "r8",
    mark: 15,
  },
  {
    label: "TBS Z-Score -1,5 SD",
    value: 1.4,
    id: "r7",
    mark: 15,
  },
  {
    label: "TBS Z-Score -1,0 SD",
    value: 1.3,
    id: "r6",
    mark: 15,
  },
];

export const neurologie = [
  {
    label: "Multiple Sklerose",
    value: 2.1,
    id: "n2",
    mark: 20,
  },
  {
    label: "> 1 Sturz in den vergangenen 12 Monaten (1 Jahres RR)",
    value: 2.0,
    id: "n9",
    mark: 20,
  },
  {
    label: "1 Sturz im letzten Jahr",
    value: 1.6,
    id: "n13",
    mark: 20,
  },
  {
    label: "Immobilität (Angewiesen auf Gehhilfe)",
    value: 1.7,
    id: "n14",
    mark: 20,
  },
  {
    label: "Timed Up-and Go-Test > 12 sek",
    value: 1.8,
    id: "n10",
    mark: 20,
  },
  {
    label: "M.Parkinson",
    value: 1.7,
    id: "n3",
    mark: 20,
  },
  {
    label: "Sturz in den vergangenen 12 Monaten",
    value: 1.6,
    id: "n5",
    mark: 20,
  },
  {
    label: "Schlaganfall",
    value: 1.6,
    id: "n1",
    mark: 20,
  },
  {
    label: "Demenz/ M.Alzheimer",
    value: 1.6,
    id: "n12",
    mark: 20,
  },
  {
    label: "Chronische Hyponatriämie",
    value: 1.4,
    id: "n7",
    mark: 20,
  },
  {
    label: "Depression/Antidepressiva",
    value: 1.3,
    id: "n6",
    mark: 20,
  },
  {
    label: "Epilepsie und Antikonvulsiva",
    value: 1.2,
    id: "n4",
    mark: 20,
  },
];

export const rheuma = [
  {
    label: "Axiale Spondyloarthritis/Spondylitis ankylosans",
    value: 1.6,
    id: "r1",
    mark: 15,
  },
  {
    label: "Rheumatoide Arthritis",
    value: 2.7,
    id: "mGC5",
    mark: 99,
  },
  {
    label: "Prednisolonäquivalenz bis 2.5mg/Tag > 3 Monate",
    value: 1.4,
    id: "r10",
    mark: 15,
  },
  {
    label: "Prednisolonäquivalenz bis 2.5mg - 7.5mg/Tag > 3 Monate",
    value: 2.3,
    id: "r11",
    mark: 15,
  },
  {
    label: "Prednisolonäquivalenz bis > 7.5mg/Tag > 3 Monate",
    value: 4.0,
    id: "r12",
    mark: 15,
  },
  {
    label: "Prednisolonäquivalenz ab > 7.5mg/Tag , neu im letzten Jahr",
    value: 4.9,
    id: "r13",
    mark: 15,
  },
];

export const threePercentThresholdFemale = {
  50: {
    "Ohne BMD": 13,
    "0.0": 13,
    "-0.5": 9,
    "-1.0": 7,
    "-1.5": 5,
    "-2.0": 4,
    "-2.5": 3,
    "-3.0": 2.1,
    "-3.5": 1.5,
    "-4.0": 0.0, // Added
  },
  55: {
    "Ohne BMD": 8,
    "0.0": 8,
    "-0.5": 6,
    "-1.0": 5,
    "-1.5": 3.5,
    "-2.0": 2.6,
    "-2.5": 1.9,
    "-3.0": 1.4,
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  60: {
    "Ohne BMD": 5,
    "0.0": 6,
    "-0.5": 4,
    "-1.0": 3,
    "-1.5": 2.4,
    "-2.0": 1.8,
    "-2.5": 1.3,
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  65: {
    "Ohne BMD": 3,
    "0.0": 4,
    "-0.5": 3,
    "-1.0": 2.3,
    "-1.5": 1.7,
    "-2.0": 1.2,
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  70: {
    "Ohne BMD": 1.7,
    "0.0": 3,
    "-0.5": 2.2,
    "-1.0": 1.6,
    "-1.5": 1.2,
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  75: {
    "Ohne BMD": 1.1,
    "0.0": 2.3,
    "-0.5": 1.7,
    "-1.0": 1.2,
    "-1.5": 0.0, // Added
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  80: {
    "Ohne BMD": 0.0, // Added
    "0.0": 1.8, // Added
    "-0.5": 1.3,
    "-1.0": 0.0,
    "-1.5": 0.0, // Added
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  85: {
    "Ohne BMD": 0.0, // Added
    "0.0": 1.5, // Added
    "-0.5": 1.1,
    "-1.0": 0.0,
    "-1.5": 0.0, // Added
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  90: {
    "Ohne BMD": 0.0, // Added
    "0.0": 1.2, // Added
    "-0.5": 0.0,
    "-1.0": 0.0, // Added
    "-1.5": 0.0, // Added
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
};

export const fivePercentThresholdFemale = {
  50: {
    "Ohne BMD": 22,
    "0.0": 21,
    "-0.5": 16,
    "-1.0": 12,
    "-1.5": 9,
    "-2.0": 6,
    "-2.5": 5,
    "-3.0": 3.5,
    "-3.5": 2.5,
    "-4.0": 2,
  },
  55: {
    "Ohne BMD": 13,
    "0.0": 14,
    "-0.5": 10,
    "-1.0": 8,
    "-1.5": 6,
    "-2.0": 4,
    "-2.5": 3,
    "-3.0": 2.3,
    "-3.5": 1.7,
    "-4.0": 0.0, // Added
  },
  60: {
    "Ohne BMD": 8,
    "0.0": 10,
    "-0.5": 7,
    "-1.0": 5,
    "-1.5": 4,
    "-2.0": 3,
    "-2.5": 2.2,
    "-3.0": 1.6,
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  65: {
    "Ohne BMD": 5,
    "0.0": 7,
    "-0.5": 5,
    "-1.0": 4,
    "-1.5": 3,
    "-2.0": 2.1,
    "-2.5": 1.5,
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  70: {
    "Ohne BMD": 2.8,
    "0.0": 5,
    "-0.5": 4,
    "-1.0": 2.7,
    "-1.5": 2,
    "-2.0": 1.5,
    "-2.5": 1.1,
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  75: {
    "Ohne BMD": 1.8,
    "0.0": 4,
    "-0.5": 3,
    "-1.0": 2.1,
    "-1.5": 1.5,
    "-2.0": 1.1,
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  80: {
    "Ohne BMD": 1.1,
    "0.0": 3,
    "-0.5": 2.2,
    "-1.0": 1.6,
    "-1.5": 1.2,
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  85: {
    "Ohne BMD": 0.0, // Added
    "0.0": 2.4, // Added
    "-0.5": 1.8,
    "-1.0": 1.3,
    "-1.5": 0.0,
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  90: {
    "Ohne BMD": 0.0, // Added
    "0.0": 2, // Added
    "-0.5": 1.4,
    "-1.0": 0.0,
    "-1.5": 0.0, // Added
    "-2.0": 0.0, // Added
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
};

export const tenPercentThresholdFemale = {
  50: {
    "Ohne BMD": 43,
    "0.0": 42,
    "-0.5": 31,
    "-1.0": 23,
    "-1.5": 17,
    "-2.0": 13,
    "-2.5": 9,
    "-3.0": 7,
    "-3.5": 5,
    "-4.0": 3.7,
  },
  55: {
    "Ohne BMD": 25,
    "0.0": 28,
    "-0.5": 21,
    "-1.0": 16,
    "-1.5": 12,
    "-2.0": 9,
    "-2.5": 6,
    "-3.0": 5,
    "-3.5": 3.5,
    "-4.0": 2.5,
  },
  60: {
    "Ohne BMD": 15,
    "0.0": 19,
    "-0.5": 14,
    "-1.0": 11,
    "-1.5": 8,
    "-2.0": 6,
    "-2.5": 4.4,
    "-3.0": 3.2,
    "-3.5": 2.4,
    "-4.0": 1.7,
  },
  65: {
    "Ohne BMD": 9,
    "0.0": 14,
    "-0.5": 10,
    "-1.0": 7.5,
    "-1.5": 6,
    "-2.0": 4,
    "-2.5": 3.1,
    "-3.0": 2.3,
    "-3.5": 1.7,
    "-4.0": 0.0, // Added
  },
  70: {
    "Ohne BMD": 6,
    "0.0": 10,
    "-0.5": 7,
    "-1.0": 5.5,
    "-1.5": 4.1,
    "-2.0": 3,
    "-2.5": 2.2,
    "-3.0": 1.6,
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  75: {
    "Ohne BMD": 3.5,
    "0.0": 8,
    "-0.5": 6,
    "-1.0": 4.2,
    "-1.5": 3.1,
    "-2.0": 2.2,
    "-2.5": 1.6,
    "-3.0": 1.2,
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  80: {
    "Ohne BMD": 2.2,
    "0.0": 6,
    "-0.5": 4.4,
    "-1.0": 3.2,
    "-1.5": 2.4,
    "-2.0": 1.7,
    "-2.5": 1.3,
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  85: {
    "Ohne BMD": 1.4,
    "0.0": 5,
    "-0.5": 3.6,
    "-1.0": 2.6,
    "-1.5": 1.9,
    "-2.0": 1.3,
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
  90: {
    "Ohne BMD": 0.0, // Added
    "0.0": 4, // Added
    "-0.5": 3,
    "-1.0": 2.1,
    "-1.5": 1.5,
    "-2.0": 0.0,
    "-2.5": 0.0, // Added
    "-3.0": 0.0, // Added
    "-3.5": 0.0, // Added
    "-4.0": 0.0, // Added
  },
};

export const threePercentThresholdMale = {
  50: {
    "Ohne BMD": 12,
    "0.0": 10,
    "-0.5": 7,
    "-1.0": 5,
    "-1.5": 3.4,
    "-2.0": 2.3,
    "-2.5": 1.6,
    "-3.0": 1.1,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  55: {
    "Ohne BMD": 9,
    "0.0": 8,
    "-0.5": 5,
    "-1.0": 3.7,
    "-1.5": 2.5,
    "-2.0": 1.7,
    "-2.5": 1.2,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  60: {
    "Ohne BMD": 6,
    "0.0": 6,
    "-0.5": 4,
    "-1.0": 2.8,
    "-1.5": 1.9,
    "-2.0": 1.3,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  65: {
    "Ohne BMD": 5,
    "0.0": 5,
    "-0.5": 3,
    "-1.0": 2.2,
    "-1.5": 1.5,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  70: {
    "Ohne BMD": 3,
    "0.0": 4,
    "-0.5": 2.5,
    "-1.0": 1.7,
    "-1.5": 1.1,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  75: {
    "Ohne BMD": 2.4,
    "0.0": 3,
    "-0.5": 2,
    "-1.0": 1.4,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  80: {
    "Ohne BMD": 1.6,
    "0.0": 2.4,
    "-0.5": 1.6,
    "-1.0": 1.1,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  85: {
    "Ohne BMD": 0.0,
    "0.0": 2,
    "-0.5": 1.3,
    "-1.0": 0.0,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  90: {
    "Ohne BMD": 0.0,
    "0.0": 1.4,
    "-0.5": 1.0,
    "-1.0": 0.0,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
};

export const fivePercentThresholdMale = {
  50: {
    "Ohne BMD": 19,
    "0.0": 17,
    "-0.5": 12,
    "-1.0": 8,
    "-1.5": 6,
    "-2.0": 4,
    "-2.5": 2.6,
    "-3.0": 1.8,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  55: {
    "Ohne BMD": 14,
    "0.0": 13,
    "-0.5": 9,
    "-1.0": 6,
    "-1.5": 4,
    "-2.0": 2.9,
    "-2.5": 2,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  60: {
    "Ohne BMD": 11,
    "0.0": 10,
    "-0.5": 7,
    "-1.0": 5,
    "-1.5": 3.2,
    "-2.0": 2.2,
    "-2.5": 1.5,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  65: {
    "Ohne BMD": 8,
    "0.0": 8,
    "-0.5": 5,
    "-1.0": 3.6,
    "-1.5": 2.4,
    "-2.0": 1.6,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  70: {
    "Ohne BMD": 6,
    "0.0": 6,
    "-0.5": 4,
    "-1.0": 2.8,
    "-1.5": 1.9,
    "-2.0": 1.3,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  75: {
    "Ohne BMD": 4,
    "0.0": 5,
    "-0.5": 3.4,
    "-1.0": 2.3,
    "-1.5": 1.5,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  80: {
    "Ohne BMD": 2.7,
    "0.0": 4,
    "-0.5": 2.7,
    "-1.0": 1.8,
    "-1.5": 1.2,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  85: {
    "Ohne BMD": 1.6,
    "0.0": 3.3,
    "-0.5": 2.1,
    "-1.0": 1.4,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  90: {
    "Ohne BMD": 0.0,
    "0.0": 2.4,
    "-0.5": 1.5,
    "-1.0": 0.0,
    "-1.5": 0.0,
    "-2.0": 0.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
};

export const tenPercentThresholdMale = {
  50: {
    "Ohne BMD": 39,
    "0.0": 33,
    "-0.5": 23,
    "-1.0": 16,
    "-1.5": 11,
    "-2.0": 8,
    "-2.5": 5,
    "-3.0": 3.6,
    "-3.5": 2.5,
    "-4.0": 1.7,
  },
  55: {
    "Ohne BMD": 29,
    "0.0": 26,
    "-0.5": 18,
    "-1.0": 12,
    "-1.5": 8,
    "-2.0": 6,
    "-2.5": 4,
    "-3.0": 2.6,
    "-3.5": 1.8,
    "-4.0": 0.0,
  },
  60: {
    "Ohne BMD": 21,
    "0.0": 20,
    "-0.5": 14,
    "-1.0": 9,
    "-1.5": 6,
    "-2.0": 4,
    "-2.5": 3,
    "-3.0": 1.9,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  65: {
    "Ohne BMD": 15,
    "0.0": 16,
    "-0.5": 11,
    "-1.0": 7,
    "-1.5": 5,
    "-2.0": 3,
    "-2.5": 2.2,
    "-3.0": 1.5,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  70: {
    "Ohne BMD": 11,
    "0.0": 12,
    "-0.5": 8,
    "-1.0": 6,
    "-1.5": 4,
    "-2.0": 2.5,
    "-2.5": 1.7,
    "-3.0": 1.0,
    "-3.5": 1.0,
    "-4.0": 1.0,
  },
  75: {
    "Ohne BMD": 8,
    "0.0": 10,
    "-0.5": 7,
    "-1.0": 4.5,
    "-1.5": 3,
    "-2.0": 2,
    "-2.5": 1.3,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  80: {
    "Ohne BMD": 5,
    "0.0": 8,
    "-0.5": 5,
    "-1.0": 3.6,
    "-1.5": 2.4,
    "-2.0": 1.6,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  85: {
    "Ohne BMD": 3.2,
    "0.0": 7,
    "-0.5": 4,
    "-1.0": 2.8,
    "-1.5": 1.8,
    "-2.0": 1.2,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
  90: {
    "Ohne BMD": 1.6,
    "0.0": 5,
    "-0.5": 3,
    "-1.0": 2,
    "-1.5": 1.3,
    "-2.0": 1.0,
    "-2.5": 0.0,
    "-3.0": 0.0,
    "-3.5": 0.0,
    "-4.0": 0.0,
  },
};
