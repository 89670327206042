import React, { useState, useEffect } from "react";
import "./App.css";
import RiskFactorsModal from "./components/RiskFactorsModal";
import AgeSelectorModal from "./components/AgeSelectorModal";
import GenderSelectorModal from "./components/GenderSelectorModal";
import TValueSelectorModal from "./components/TValueSelectorModal";
import DisclaimerModal from "./components/DisclaimerModal";
import HandleCalculate from "./functions/handleCalculate";
import bvouimage from "./images/bvou_logo_neu.png";

function App() {
  const [isAgeModalVisible, setIsAgeModalVisible] = useState(false);
  const [selectedAge, setSelectedAge] = useState("50");

  const [isGenderModalVisible, setIsGenderModalVisible] = useState(false);
  const [selectedGender, setSelectedGender] = useState("Weiblich");

  const [isTValueModalVisible, setIsTValueModalVisible] = useState(false);
  const [selectedTValue, setSelectedTValue] = useState("Ohne BMD");

  const [isRiskFactorsModalVisible, setIsRiskFactorsModalVisible] =
    useState(false);
  const [selectedRiskFactors, setSelectedRiskFactors] = useState([]);

  const [calculatedResult, setCalculatedResult] = useState(0);
  const [thresholdResult, setThresholdResult] = useState("");

  const handleResultChange = (newResult, newThreshold) => {
    console.log("Results received in App:", newResult, newThreshold);
    setCalculatedResult(newResult);
    setThresholdResult(newThreshold);
  };

  const [isDisclaimerModalVisible, setIsDisclaimerModalVisible] =
    useState(false);

  const toggleAgeModal = () => {
    setIsAgeModalVisible(!isAgeModalVisible);
  };

  const handleAgeSelect = (age) => {
    setSelectedAge(age);
  };

  const toggleGenderModal = () => {
    setIsGenderModalVisible(!isGenderModalVisible);
  };

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
  };

  const toggleTValueModal = () => {
    setIsTValueModalVisible(!isTValueModalVisible);
  };

  const handleTValueSelect = (tvalue) => {
    setSelectedTValue(tvalue);
  };

  const toggleRiskFactorsModal = () => {
    setIsRiskFactorsModalVisible(!isRiskFactorsModalVisible);
  };

  const handleRiskFactorsSelect = (factors) => {
    setSelectedRiskFactors(factors);
    console.log(factors);
  };

  useEffect(() => {
    const hasSeenDisclaimer = localStorage.getItem("hasSeenDisclaimer");
    if (!hasSeenDisclaimer) {
      setIsDisclaimerModalVisible(true); // Show modal if they haven't seen it
    }
  }, []);

  const toggleDisclaimerModal = () => {
    if (!isDisclaimerModalVisible) {
      // When closing the modal, set local storage
      localStorage.setItem("hasSeenDisclaimer", "true");
    }
    setIsDisclaimerModalVisible(!isDisclaimerModalVisible);
  };

  const handleReset = () => {
    setSelectedRiskFactors([]); // This will clear the array
    setSelectedGender("Weiblich");
    setSelectedAge("50");
    setSelectedTValue("Ohne BMD");
  };

  const riskFactors = [
    "Wirbelfraktur(en)",
    "Allgemeine Risikofaktoren",
    "Sturzrisiko assoziierte Risikofaktoren/Geriatrie",
    "Weitere Erkrankungen/ Medikationen",
    "Indikationen für Basisdiagnostik",
    "Hüftfraktur und andere Frakturen",
    "Rheumatologie und Glukokortikoide",
    "Endokrinologie",
    "Trabecular Bone Score (TBS)",
    "Weiter",
  ];

  return (
    <div className="App">
      <img src={bvouimage} alt="BVOU logo" className="smallTopLeft" />

      <header className="App-header">Osteoporose Risiko Wissen</header>
      <div className="grid-container">
        {/* First row with two columns */}
        <div className="roundedBox full-width">
          <div className="boxTitle">Frakturrisiko / 3 Jahre</div>
          <div
            className="boxContent"
            style={{
              color:
                thresholdResult === "Unter 3%"
                  ? "#033F78"
                  : thresholdResult === ">3%"
                  ? "green"
                  : thresholdResult === ">5%"
                  ? "orange"
                  : thresholdResult === ">10%"
                  ? "red"
                  : "black",
            }}
          >
            {thresholdResult}
          </div>
        </div>
        <div className="roundedBox full-width">
          <div className="boxTitle">Falldaten</div>
          <div className="row">
            <div className="column">
              <div className="row">
                <div className="property">Alter: </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="property">T-Wert: </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="property">Risikofaktoren: </div>
              </div>
            </div>
            <div className="column">
              <div className="row">
                <div className="dynamicValue">{selectedAge}</div>
              </div>
              <hr className="separator"></hr>
              <div className="row">
                <div className="dynamicValue">{selectedTValue}</div>
              </div>
              <hr className="separator"></hr>
              <div className="row">
                <div className="dynamicValue">
                  {selectedRiskFactors.length === 0 ? (
                    <div style={{ color: "red" }}>
                      Keine Risikofaktoren gewählt
                    </div>
                  ) : (
                    selectedRiskFactors
                      .sort((a, b) => b.value - a.value) // Sort by value in descending order
                      .slice(0, 1) // Take only the first two items
                      .map((selectedItem, index) => (
                        <div key={selectedItem.id}>
                          {index === 0 ? " 1) " : " 1) "}
                          {selectedItem.label} ({selectedItem.value})
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
            <div className="column">
              <div className="row">
                <div className="property">Geschlecht: </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="property">Indiktionsfaktor: </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="dynamicValue">
                  {selectedRiskFactors.length === 0 ? (
                    <div style={{ color: "red" }}>
                      Keine Risikofaktoren gewählt
                    </div>
                  ) : (
                    selectedRiskFactors
                      .sort((a, b) => b.value - a.value) // Sort by value in descending order
                      .slice(1, 2) // Take only the first two items
                      .map((selectedItem, index) => (
                        <div key={selectedItem.id}>
                          {index === 0 ? " 2) " : " 2) "}
                          {selectedItem.label} ({selectedItem.value})
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
            <div className="column">
              <div className="row">
                <div className="dynamicValue">{selectedGender}</div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="dynamicValue">
                  {calculatedResult.toFixed(2)}
                </div>
              </div>
              <hr></hr>
            </div>
          </div>
        </div>

        <div className="button-col">
          <button onClick={toggleAgeModal}>Alter</button>
          <button onClick={toggleTValueModal}>T-Wert</button>
        </div>
        <div className="button-col">
          <button onClick={toggleGenderModal}>Geschlecht</button>
          <button onClick={toggleRiskFactorsModal}>Riskofaktoren</button>
        </div>
        <HandleCalculate
          geschlecht={selectedGender}
          alter={selectedAge}
          tWert={selectedTValue}
          selectedValues={selectedRiskFactors}
          onResultChange={handleResultChange}
        />
        <AgeSelectorModal
          isVisible={isAgeModalVisible}
          onClose={toggleAgeModal}
          onSelect={handleAgeSelect}
        />
        <GenderSelectorModal
          isVisible={isGenderModalVisible}
          onClose={toggleGenderModal}
          onSelect={handleGenderSelect}
        />
        <TValueSelectorModal
          isVisible={isTValueModalVisible}
          onClose={toggleTValueModal}
          onSelect={handleTValueSelect}
        />
        <RiskFactorsModal
          isVisible={isRiskFactorsModalVisible}
          onClose={toggleRiskFactorsModal}
          riskFactors={riskFactors}
          onSelect={handleRiskFactorsSelect}
          selectedAge={selectedAge}
          selectedRiskFactors={selectedRiskFactors}
        />

        {/* Third row with one column */}
        <div className="button-col full-width">
          <div className="reset-button" onClick={handleReset}>
            Neuer Fall
          </div>
        </div>
        <div className="button-row full-width">
          <a href="https://www.bvou.net/" className="link-button">
            BVOU
          </a>
          <a
            href="https://dv-osteologie.org/osteoporose-leitlinien"
            className="link-button"
          >
            DVO Leitlinie
          </a>
          <div className="link-button" onClick={toggleDisclaimerModal}>
            Disclaimer
          </div>
          {isDisclaimerModalVisible && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2>Disclaimer</h2>
                <p>Your disclaimer text here.</p>
                <button onClick={toggleDisclaimerModal}>Close</button>
              </div>
            </div>
          )}
          <DisclaimerModal
            isVisible={isDisclaimerModalVisible}
            onClose={toggleDisclaimerModal}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
