import React, { useState } from "react";

const AgeSelectorModal = ({ isVisible, onClose, onSelect }) => {
  const [selectedAge, setSelectedAge] = useState("50");

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
  };

  const handleSubmit = () => {
    onSelect(selectedAge);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Alter wählen</h2>
        <select value={selectedAge} onChange={handleAgeChange}>
          <option value="" disabled>
            Alter
          </option>
          {[50, 55, 60, 65, 70, 75, 80, 85, 90].map((age) => (
            <option key={age} value={age}>
              {age}
            </option>
          ))}
        </select>
        <button
          onClick={handleSubmit}
          disabled={!selectedAge}
          className="confirm" // Apply the class for styling
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default AgeSelectorModal;
