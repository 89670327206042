import React, { useState, useEffect } from "react";

import {
  fivePercentThresholdFemale,
  fivePercentThresholdMale,
  tenPercentThresholdFemale,
  tenPercentThresholdMale,
  threePercentThresholdFemale,
  threePercentThresholdMale,
} from "../database/factors";

function HandleCalculate({
  geschlecht,
  alter,
  tWert,
  selectedValues,
  onResultChange,
}) {
  const [result, setResult] = useState(0);
  const [thresholdResult, setThresholdResult] = useState("");

  const calculateThresholdFemale = (femaleResult) => {
    const threePercentValue = getThresholdFemale(threePercentThresholdFemale);
    const fivePercentValue = getThresholdFemale(fivePercentThresholdFemale);
    const tenPercentValue = getThresholdFemale(tenPercentThresholdFemale);

    if (
      (tenPercentValue !== null && femaleResult >= tenPercentValue) ||
      femaleResult === tenPercentValue
    ) {
      return ">10%";
    } else if (
      (fivePercentValue !== null && femaleResult >= fivePercentValue) ||
      femaleResult === fivePercentValue
    ) {
      return ">5%";
    } else if (
      (threePercentValue !== null && femaleResult >= threePercentValue) ||
      femaleResult === threePercentValue
    ) {
      return ">3%";
    } else {
      return "Unter 3%";
    }
  };

  const calculateThresholdMale = (maleResult) => {
    const threePercentValue = getThresholdMale(threePercentThresholdMale);
    const fivePercentValue = getThresholdMale(fivePercentThresholdMale);
    const tenPercentValue = getThresholdMale(tenPercentThresholdMale);

    if (tenPercentValue !== null && maleResult >= tenPercentValue) {
      return ">10%";
    } else if (fivePercentValue !== null && maleResult >= fivePercentValue) {
      return ">5%";
    } else if (threePercentValue !== null && maleResult >= threePercentValue) {
      return ">3%";
    } else {
      return "Unter 3%";
    }
  };

  const getThresholdFemale = (thresholdTable) => {
    if (!thresholdTable[alter]) {
      return null;
    }

    const thresholdValue = thresholdTable[alter][tWert];

    if (typeof thresholdValue === "number" || thresholdValue === 0.0) {
      return thresholdValue;
    }

    return null;
  };

  const getThresholdMale = (thresholdTable) => {
    // Check if the age exists in the table
    if (!thresholdTable[alter]) {
      return null;
    }

    const thresholdValue = thresholdTable[alter][tWert];

    if (typeof thresholdValue === "number" || thresholdValue === 0.0) {
      return thresholdValue;
    }

    return null;
  };

  const calculateResult = () => {
    // Since we're not using sections anymore, we'll work directly with the selectedValues
    const items = selectedValues;

    // Sort the selected items in descending order of their value
    items.sort((a, b) => {
      if (typeof b.value === "number" && typeof a.value === "number") {
        return b.value - a.value;
      }
      return 0;
    });

    const topN = [];
    const usedIds = [];
    const usedMarks = [];

    for (const item of items) {
      if (topN.length >= 2) {
        break; // Stop when we have collected top 2 items
      }

      // Ensure the item's id is unique among the topN items
      if (usedIds.includes(item.id)) {
        continue;
      }

      // For special marks 0, 10, 20, ensure only one item per mark is included
      if ([0, 10, 20].includes(item.mark) && usedMarks.includes(item.mark)) {
        continue;
      }

      // Exclude items with mark 99 as they are not selectable
      if (item.mark === 99) {
        continue;
      }

      // Add the item to topN, and record its id and mark
      topN.push(item);
      usedIds.push(item.id);
      usedMarks.push(item.mark);
    }

    // Calculate the result by multiplying their values, starting with the first item's value
    const calculatedValue = topN.reduce(
      (acc, curr, index) => {
        // If it's the first item, return its value if it's a number, otherwise 0
        if (index === 0) {
          return typeof curr.value === "number" ? curr.value : 0;
        }
        // For subsequent items, multiply if it's a number
        if (typeof curr.value === "number") {
          return acc * curr.value;
        }
        return acc; // If current value is not a number, just return the accumulator
      },
      topN.length > 0 && typeof topN[0].value === "number" ? topN[0].value : 0
    );

    // Set the result
    setResult(calculatedValue);
    return calculatedValue;
  };

  useEffect(() => {
    const calcResult = calculateResult();

    let thresholdOutput = "";
    if (geschlecht === "Weiblich") {
      thresholdOutput = calculateThresholdFemale(calcResult);
    } else if (geschlecht === "Männlich") {
      thresholdOutput = calculateThresholdMale(calcResult);
    }

    setThresholdResult(thresholdOutput);
    onResultChange(calcResult, thresholdOutput); // Call the callback with both the numeric and threshold result
  }, [geschlecht, alter, tWert, selectedValues, onResultChange]);

  return null; // No need to return anything as we are lifting state up
}

export default HandleCalculate;
