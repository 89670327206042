import React, { useState } from "react";

const GenderSelectorModal = ({ isVisible, onClose, onSelect }) => {
  const [selectedGender, setSelectedGender] = useState("Weiblich");

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleSubmit = () => {
    onSelect(selectedGender);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Geschlecht wählen</h2>
        <select value={selectedGender} onChange={handleGenderChange}>
          <option value="" disabled>
            Geschlecht
          </option>
          <option value="Weiblich">Weiblich</option>
          <option value="Männlich">Männlich</option>
        </select>
        <button
          onClick={handleSubmit}
          disabled={!selectedGender}
          className="confirm" // Apply the class for styling
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default GenderSelectorModal;
