import React, { useState } from "react";

const TValueSelectorModal = ({ isVisible, onClose, onSelect }) => {
  const [selectedTValue, setSelectedTValue] = useState("Ohne BMD");

  const handleTValueChange = (event) => {
    setSelectedTValue(event.target.value);
  };

  const handleSubmit = () => {
    onSelect(selectedTValue);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>T-Wert wählen</h2>
        <select value={selectedTValue} onChange={handleTValueChange}>
          <option value="" disabled>
            T-Wert
          </option>
          <option value="Ohne BMD">Ohne BMD</option>
          {Array.from({ length: 9 }, (_, i) => (i * -0.5).toFixed(1)).map(
            (tvalue) => (
              <option key={tvalue} value={tvalue}>
                {tvalue}
              </option>
            )
          )}
        </select>
        <button
          onClick={handleSubmit}
          disabled={!selectedTValue}
          className="confirm" // Apply the class for styling
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default TValueSelectorModal;
