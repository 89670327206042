import React, { useState } from "react";

const DisclaimerModal = ({ isVisible, onClose }) => {
  const handleSubmit = () => {
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Disclaimer</h2>
        <p style={{ textAlign: "left" }}>
          Die App trainiert das Verständnis der Leitlinie Osteoporose
          (Langfassung) und die darin vermittelten Informationen zum Risiko,
          einen durch Knochenschwund (Osteoporose) verursachten Knochenbruch zu
          erleiden anhand exemplarischer Fälle. Die App stellt keine Diagnose
          und gibt keine Therapieempfehlung, und gibt keine
          Entscheidungsunterstützung (weder für Ärzte noch für Patienten). Es
          werden keine personenbezogenen oder Gesundheitsdaten verarbeitet, die
          auf eine einzelne Person zurückschließen lassen. Es werden auch keine
          Gesundheitsdaten aus dem Smartphone, Smartwatches oder sonstigen
          Geräten zur Kalkulation herangezogen.
        </p>
        <p style={{ textAlign: "left" }}>
          Die App ersetzt keinesfalls eine fundierte ärztliche Untersuchung,
          Diagnostik und Befunddiskussion zwischen Arzt und Patient. Diese gehen
          immer einer Therapieempfehlung voraus. Diese Therapieempfehlung wird
          die geltenden Leitlinien, im aktuellen Fall die Leitlinie Osteoporose
          in der aktuellen Fassung, in angemessener Weise berücksichtigen oder
          im Einzelfall auch davon abweichen.
        </p>
        <p style={{ textAlign: "left" }}>
          Die Nutzung der App geschieht auf eigene Verantwortung des Nutzers.
          Die von der App zur Verfügung gestellten Informationen und Angaben
          ersetzen nicht die Konsultation sowie die Diagnose- und
          Therapieentscheidung einer Ärztin oder eines Arztes und ersetzt auch
          nicht die patientenindividuelle Berechnung des Frakturrisikos anhand
          der Leitlinie.
        </p>
        <p style={{ textAlign: "left" }}>
          Bitte wenden Sie sich bei allen Fragen an Ihren behandelnden Arzt, der
          mit seiner Expertise und Fachkenntnis sowohl Ihre persönliche
          Situation einschätzen als auch die Inhalte der Leitlinie, inkl.
          persönlicher und patientenindividueller Risikoberechnung, anwenden
          kann. BVOU und Ivana-technologies haben bei der Herstellung dieser App
          und den darin enthaltenen Informationen die im Verkehr erforderliche
          Sorgfalt beachtet. Für die Richtigkeit, Vollständigkeit und Aktualität
          der in dieser App bereitgestellten Informationen und Angaben kann
          seitens des BVOU und der Ivana-technologies gleichwohl keine Haftung
          und Gewähr übernommen werden. Es wird deshalb ausdrücklich darauf
          aufmerksam gemacht, dass sich auf die Richtigkeit, Vollständigkeit und
          Aktualität der Informationen und Angaben nicht verlassen werden darf.
        </p>
        <p style={{ textAlign: "left" }}>
          Die Haftung des BVOU und Ivana-technologies für unmittelbare oder
          mittelbare Schäden jeglicher Art oder sonstige Ersatzansprüche für
          Folgeschäden aufgrund der Nutzung dieser App und der hiermit zur
          Verfügung gestellten Informationen und Angaben, ist ausgeschlossen.
        </p>
        <p style={{ textAlign: "left" }}>
          Der BVOU und Ivana-technologies sind nicht verpflichtet, die
          betreffenden Inhalte zu aktualisieren, falls sich nach der
          Veröffentlichung der Materialien und Dokumente neue Entwicklungen oder
          Umstände ergeben.
        </p>
        <button
          onClick={handleSubmit}
          className="confirm" // Apply the class for styling
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default DisclaimerModal;
